import {
  authenticationGuard,
  adminGuard,
  startpageGuard,
  favoritePageGuard,
} from '@/router/guards';

export default {
  path: '',
  beforeEnter: authenticationGuard,
  component: () => import('@/templates/Default'),
  children: [
    {
      path: '',
      beforeEnter: startpageGuard,
      name: 'home',
      component: () => import('@/views/dashboard'),
    },
    {
      path: '',
      beforeEnter: favoritePageGuard,
      name: 'favorite',
      component: () => import('@/views/dashboard'),
    },
    {
      path: '/dashboard/:dashboardId',
      name: 'dashboard.show',
      component: () => import('@/views/dashboard'),
    },
    {
      path: 'oauth/unauthorized',
      name: 'oauth.unauthorized',
      component: () => import('@/views/dashboard'),
    },
    {
      path: '/gebruikers',
      name: 'users',
      beforeEnter: adminGuard,
      component: () => import('@/views/user/UserResource'),
    },
    {
      path: '/rollen',
      name: 'roles',
      beforeEnter: adminGuard,
      component: () => import('@/views/role/Table'),
    },
    {
      path: '/profiel',
      name: 'profile',
      component: () => import('@/views/profile'),
    },
    {
      path: '/sales-order',
      name: 'salesOrder',
      component: () => import('@/views/salesOrder/SalesOrderTable.vue'),
    },
    {
      path: '/filtered-sales-orders',
      name: 'FilteredSalesOrder',
      component: () => import('@/views/salesOrder/FilteredSalesOrderTable.vue'),
    },
    {
      path: '/dashboards',
      name: 'dashboards',
      beforeEnter: adminGuard,
      component: () => import('@/views/dashboard/Table.vue'),
    },
    {
      path: '/rapportage/:reportType',
      name: 'report.show',
      component: () => import('@/views/Report.vue'),
    },
  ],
};
