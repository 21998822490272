import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import nl from 'vuetify/es5/locale/nl';

import '@fortawesome/fontawesome-free/css/all.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
        values: {
            'close': 'fas fa-times',
            'menu': 'fas fa-bars',
            'delete': 'fas fa-trash-alt',
            'add': 'fas fa-plus',
            'edit': 'fas fa-pencil-alt',
            'search': 'fas fa-search',

            'dashboard': 'fas fa-home',
            'user': 'fas fa-users',
            'users': 'fas fa-users',
            'role': 'fas fa-user-tag',
            'shown': 'fas fa-eye',
            'hidden': 'fas fa-eye-slash',
            'favorite': 'fas fa-bookmark',

            'cubes': 'fas fa-cubes',
            'plus': 'fas fa-plus',
            'check': 'fas fa-check'
        },
    },
    lang: {
        locales: {nl},
        current: 'nl',
    },
    theme: {
        themes: {
            light: {
                primary: '#34A2AB',
                accent: '#34A2AB',
            },
        },
    },
});
